.section {
    /*background-color: rgb(234, 234, 234);*/
    padding: 88px 0;
}

.recipes_container {
    /*width: 100%;*/
    /*display: grid;*/
    /*grid-template-columns: repeat(4, 1fr);*/
    /*grid-gap: 1px;*/
}

.recipe_wrapper {
    padding-top: 100%;
    height: 0;
    position: relative;
}

.recipe_block {
    aspect-ratio: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recipe_block:nth-child(4n + 1) {
    background-color: #FD7E60;
}
.recipe_block:nth-child(4n + 2) {
    background-color: #5CE0B0;
}
.recipe_block:nth-child(4n + 3) {
    background-color: #EE9000;
}
.recipe_block:nth-child(4n + 4) {
    background-color: #42210A;
}

.pan {
    font-size: 256px;
    transition: all 600ms ease-in-out;
    cursor: pointer;
}

.pan_text {
    position: absolute;
    top: 44%;
    transform: translateY(-60%);
    left: 49%;
    opacity: 1;
    transition: all 600ms ease-in-out;
}

.pan_avatar {
    position: absolute;
    left: 17%;
    top: 21%;
    width: 120px;
    height: 120px;
    opacity: 0;
    z-index: -2;
}

.recipe_block:hover > .pan_avatar {
    opacity: 1;
}

.pan__container {
    position: relative;
}

.pan__container:hover .pan {
    transform: rotate(-90deg);
}


.play {
    width: 40px;
    height: 40px;
    opacity: 0;
    transition: all 600ms ease-in-out;
    color: #FFFFFF;
}

.play__button {
    position: absolute;
    left: 32%;
    top: 33%;
    z-index: 2;
}

.pan__container:hover .play {
    opacity: 1;
}
.pan__container:hover .pan_text {
    opacity: 0;
}




@media (max-width: 900px) {
    .section {
        padding: 68px 0;
    }
}